<template>
  <v-container>
    <v-row class="my-10">
      <v-col cols="12"
             class="text-center">
        <v-icon class="text-h1 success--text">mdi-check-circle</v-icon>
      </v-col>
      <v-col>
        <v-card
            color="#F8F8F8"
            tile
            elevation="0"
        >
          <v-card-title>¡ENHORABUENA!</v-card-title>
          <v-card-subtitle>El pedido #{{orderId}} se ha realizado exitosamente.</v-card-subtitle>
          <v-card-text>Te notificaremos por sms cuando tu pedido esté listo</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "confirm",
  mounted() {
    this.$store.commit('setLoading', false);
  },
  computed:{
    orderId() {
      return this.$route.query.id || null
    },
  }
}
</script>

<style scoped>

</style>